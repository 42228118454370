<template>
  <div class="profile-dropdown">
    <div data-bs-toggle="dropdown" aria-expanded="false" class="pr-name">
      <span class="profile-img"><i class="bi bi-person-circle"></i></span>
      <span class="us-name">{{ userName }}</span>
    </div>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li>
        <router-link :to="{ name: profileUrl }" class="dropdown-item"
          >Profile</router-link
        >
      </li>
      <li><a class="dropdown-item" v-on:click="userLogout">Logout</a></li>
    </ul>
  </div>
</template>
<script>
import callService from "@/services/Service";
const { getData, getByID, postData } = callService();
export default {
  name: "Common",

  data() {
    return {
      userName: JSON.parse(localStorage.getItem("LogIn")).user_fname,
      profileUrl: null,
    };
  },

  mounted() {
    if (localStorage.getItem("LogIn")) {
      const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
      const redirction =
        type_uid === "ipg4bi4vg5"
          ? "SuperUserProfile"
          : type_uid === "ipg4bi4vg6"
          ? "EnrollmentAdminProfile"
          : type_uid === "ipg4bi4vg7"
          ? "ClinicalAdminProfile"
          : type_uid === "ipg4bi4vg8"
          ? "GeneralAdminProfile"
          : type_uid === "ipg4bi4v10"
          ? "agentprofile"
          : type_uid === "ipg4bi4vg9"
          ? "EnrollmentManagerProfile"
          : type_uid === "ipg4bi4v11"
          ? "PhysicianAdminProfile"
          : type_uid === "ipg4bi4v12"
          ? "PhysicianProfile"
          : type_uid === "Enrollment"
          ? "managerProfile"
          : "/login";
      this.profileUrl = redirction;
    }
  },
  methods: {
    userLogout() {
      this.updateLogoutStatus();
    },
    async updateLogoutStatus() {
      const user_uid = JSON.parse(localStorage.getItem("LogIn")).user_uid;
      try {
        const resData = await postData("login/updateLoginStatus", {
          user_uid: user_uid,
          login_status: 0,
        });
        if (resData.status === 200 && resData.data.statuscode === 1) {
          localStorage.clear();
          this.$router
            .push({
              name: "login",
            })
            .then(() => {
              this.$router.go();
            });
        }
      } catch (e) {
        this.error = e;
      }
    },
  },
};
</script>


