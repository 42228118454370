import axios from "axios";
export default function callService() {
    var serviceUrl = '';
    if (window.location.hostname == 'localhost' || window.location.hostname.indexOf('192.168.0') > -1){
        serviceUrl = "https://192.168.0.87:8044/";        
    }else{
        serviceUrl = "https://inspiredhealthhub.com:8044/";
    }
    const getData = async (parametter) => {
        try {
            return await axios.get(serviceUrl + parametter);
        } catch (error){
            return error; 
        }
    }
    const postData = async (parametter, formData) => {
        try {
            const config = {
                method: 'POST',
                url: serviceUrl + parametter,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(formData)
            }
            return await axios(config);
        } catch (error) {
            return error;
        }
    }
    const putData = async (parametter, formData) => {
        try {
            const config = {
                method: 'POST',
                url: serviceUrl + parametter,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(formData)
            }
            return await axios(config);
        } catch (error) {
            return error;
        }
    }
    const deleteData = async (parametter, formData) => {
        try {
            const config = {
                method: 'POST',
                url: serviceUrl + parametter,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(formData)
            }
            return await axios(config);
        } catch (error) {
            return error;
        }
    }
    const getByID = async (parametter, uid) => {
        try {
            return await axios.get(serviceUrl + parametter + uid)
        } catch (error) {
            return error;
        }
    }
    const getDatabyuid = async (parametter, uid) => {
        try {
            return await axios.get(serviceUrl + parametter + uid)
        } catch (error) {
            return error;
        }
    }
    const traceDatabyuid = async (crtfn, formData) => {
        try {
            const config = {
                method: 'POST',
                url: serviceUrl + crtfn,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(formData)
            }
            return await axios(config);
        } catch (error) {
            return error;
        }
    }
    const postFileData = async (parametter, formData) => {
        try {
            const config = {
                method: 'POST',
                url: serviceUrl + parametter,
                headers: {
                    'Content-Type': `multipart/form-data;`
                },
                data: JSON.stringify(formData)
            }
            return await axios(config);
        } catch (error) {
            return error;
        }
    }
    return {
        getData,
        postData,
        putData,
        deleteData,
        getByID,
        postFileData,
        getDatabyuid,
        traceDatabyuid,
    }
}        
