<template>
  <div>
    <header class="pwlphub-header sidebarClone w-100" style="z-index: 1">
      <div class="haeder-left w-100">
        <div class="header-logo w-100 p-0 border-0">
          <img src="../assets/img/logo.png" />
        </div>
      </div>
    </header>
    <main class="sidebarClone w-100 userEnrollmentOuter main-pwlphub">
      <section class="inner-page-outer">
        <div
      class="alert alert-success"
      :class="{ 'alert-active': successMessage != null }"
    >
      <strong>Success!</strong> {{ successMessage }}
    </div>
    <div
      class="alert alert-danger"
      :class="{ 'alert-active': errorMessage != null }"
    >
      <strong>Error!</strong> {{ errorMessage }}
    </div>
        <!-- <div class="page-heading">
                <h1>Consultation Form</h1>
            </div> -->
        <section class="multi-form-outer">
          <div class="stepper-heading">
            <!--<div class="step" :class="{ 'step-active': step === 1, 'step-done': step > 1 }">
                        <span>01</span>
                        <h2>Get Ready for a Healthier You!</h2>
                    </div>-->
          </div>
          <form class="form" @submit.prevent="submitForm">
            <div class="custom-form-row">
              <div class="inputCol inputColSec">
                <label for=""
                  >Pharmacy Name
                  <span class="text-danger error-text"
                    >*
                    <span v-if="v$.name.$error">
                      Pharmacy Name is required
                    </span>
                  </span>
                </label>
                <input
                  type="text"
                  class="custom-field"
                  v-model="formFields.name"
                />
              </div>
              <div class="inputCol inputColSec">
                <label for=""
                  >Contact Person
                  <span class="text-danger error-text"
                    >*
                    <span v-if="v$.contact_person.$error">
                      Contact Person is required
                    </span>
                  </span>
                </label>
                <input
                  type="text"
                  class="custom-field"
                  v-model="formFields.contact_person"
                />
              </div>
            </div>
            <div class="custom-form-row">
              <div class="inputCol inputColSec">
                <label for=""
                  >Phone
                  <span class="text-danger error-text"
                    >*
                    <span v-if="alert_message">{{ alert_message }}</span>
                    <span v-if="v$.phone.$error"> Phone is required </span>
                  </span>
                </label>
                <input
                  type="number"
                  @blur="acceptNo(formFields.phone)"
                  class="custom-field"
                  v-model="formFields.phone"
                />
              </div>
              <div class="inputCol inputColSec">
                <label for=""
                  >Email
                  <span class="text-danger error-text"
                    >*
                    <span v-if="v$.email.$error"> Email is required </span>
                  </span>
                </label>
                <input
                  type="email"
                  class="custom-field"
                  v-model="formFields.email"
                  @blur="acceptemail(formFields.email)"
                />
              </div>
            </div>
            <div class="custom-form-row">
              <div class="inputCol inputColSec">
                <label for=""
                  >Address
                  <span class="text-danger error-text"
                    >*
                    <span v-if="v$.address.$error"> Address is required </span>
                  </span>
                </label>
                <input
                  type="text"
                  class="custom-field"
                  v-model="formFields.address"
                />
              </div>
              <div class="inputCol inputColSec">
                <label for=""
                  >Pharmacy Code
                  <span class="text-danger error-text"
                    >*
                    <span v-if="v$.pharmacy_id.$error">
                      Pharmacy Code is required
                    </span>
                  </span>
                </label>
                <input
                  type="text"
                  class="custom-field"
                  v-model="formFields.pharmacy_id"
                />
              </div>
            </div>
            <div>
              <!-- <QRCodeVue3 value="Simple QR code" /> -->
              <!-- <QRCodeVue3
                    :width="200"
                    :height="200"
                    image="/favicon.png"
                    value="https://scholtz.sk"
                    :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                    :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
                    :dotsOptions="{
                        type: 'dash',
                        color: '#0872a1',
                        gradient: {
                        type: 'linear',
                        rotation: 0,
                        colorStops: [
                            { offset: 0, color: '#0872a1' },
                            { offset: 1, color: '#0872a1' },
                        ],
                        },
                    }"                    
                    :backgroundOptions="{ color: '#ffffff' }"
                    :cornersSquareOptions="{ type: 'dot', color: '#0872a1' }"
                    :cornersDotOptions="{ type: undefined, color: '#91c651' }"
                    fileExt="png"
                    :download="true"
                    myclass="my-qur"
                    imgclass="img-qr"
                    downloadButton="my-button"
                    :downloadOptions="{ name: 'vqr', extension: 'png' }"                    
                    /> -->
            </div>
            <div class="custom-form-row">
              <button
                class="custom-button tableButton ms-auto"
                type="submit"
                value="Next"
                v-bind:disabled="alert_message != null ? true : false"
              >
                Submit
              </button>
            </div>
          </form>
        </section>
      </section>
    </main>
    <FooterPageVue />
  </div>
</template>

<script>
import FooterPageVue from "../views/include/Footer.vue";
import moment from "moment";
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import callService from "@/services/Service";
const { getData, getByID, postData } = callService();
export default {
  name: "ConsultationForm",
  components: {
    FooterPageVue,
  },
  data: () => {
    return {
      isValid: false,
      today: moment().format("YYYY-MM-DD"),
      minday: moment().subtract(100, "years").format("YYYY-MM-DD"),
      c_name: "alert alert-success",
      step: 1,
      stateData: [],
      successMessage: null,
      errorMessage:null,
    };
  },  
  setup() {
    const formFields = reactive({
      added_by: "Self",
      pharmacy_id: "",      
      name: "",
      address: "",
      contact_person: "",
      phone: "",
      email: "",      
    });
    const rules = {
      pharmacy_id: {
        required,
      },
      name: {
        required,
      },
      address: {
        required,
      },
      contact_person: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
      },
    };
    const v$ = useVuelidate(rules, formFields);
    return {
      formFields,
      v$,
    };
  },
  async mounted() {
    try {
      const responseState = await getData("administrator/common/getStates");
      if (responseState.status === 200 && responseState.data.statuscode === 1) {
        this.stateData = responseState.data.result;
      }
    } catch (e) {
      this.error = e;
    }

    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    var string_length = 8;
    var randomstring = '';
    for (var i = 0; i < string_length; i++) {
        var rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars[rnum];
    }
    this.formFields.pharmacy_id = randomstring;
    console.log("Generate Code-",randomstring);      

  },
  methods: {
    acceptNo(val) {
        var x = val.toString().replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.formFields.phone = !x[2] ?
            x[1] :
              + x[1] +  x[2] + (x[3] ?  x[3] : "");
    },
    acceptemail(val){
      if(this.checkemail(val)){
        //this.successMessage =  val + ' is valid email.';        
        this.errorMessage = null;
      } else{
        this.errorMessage = val + ' is invalid email.';        
        this.successMessage = null;
      }
    },
    checkemail(val) {
      if(val!=null){
         return val.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      }
        
    },
    
    async uniqueUsername() {
      try {
        const resData = await postData(
          "administrator/superuser/checkUserName",
          {
            user_username: this.formFields.user_username,
          }
        );
        if (resData.status === 200 && resData.data.statuscode == 0) {
          this.alert_message =
            "This username " +
            this.formFields.user_username +
            " is already exists.";
        } else {
          this.alert_message = null;
        }
      } catch (e) {
        this.error = e;
      }
    },
    hideMessage(){
        this.successMessage = null;
        this.errorMessage = null;
    },
    async submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          const resData = await postData(
            "administrator/superUser/addPharmacy",
            this.formFields
          );
          if (resData.status === 200 && resData.data.statuscode === 1) {
            this.successMessage =
              "Pharmacy has been created successfully !";              
            setTimeout(
              function () {
                this.hideMessage();
                this.formFields.pharmacy_id= "";
                this.formFields.name= "";
                this.formFields.address= "";
                this.formFields.contact_person= "";
                this.formFields.phone= "";
                this.formFields.email= "";     
              }.bind(this),
              4000
            );
          }else{
              this.errorMessage = resData.data.message;
              setTimeout(
              function () {
                this.hideMessage()
              }.bind(this),
              4000
            );
              
          }
        } catch (e) {
          this.error = e;            
        }
      }
    },
  },
};
</script>
