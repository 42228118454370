<template>
  <div class="loginOuter" style="height: 94vh">
    <div class="loginForm custom-form">
      <div class="logo-heaer">
        <img src="../assets/img/logo.png" />
      </div>
      <form class="loginField" @submit.prevent="submitForm">
        <div
          class="alert alert-danger"
          :class="{ 'alert-active': errorMessage != null }"
        >
          <strong>Error!</strong> {{ errorMessage }}
        </div>
        <div class="inputCol">
          <label
            >Group Type
            <span class="text-danger error-text">
              * <span v-if="v$.usergroupuid.$error"> select an option </span>
            </span></label
          >
          <select
            v-model="formFields.usergroupuid"
            @change="selectGroup(formFields.usergroupuid)"
            class="custom-field"
            :class="{ errorCustomField: v$.usergroupuid.$error }"
          >
            <option
              v-for="(item, index) in userGroup"
              :key="index"
              v-bind:value="item.type_uid"
            >
              {{ item.type_user }}
            </option>
          </select>
        </div>
        <div class="inputCol">
          <label
            >User Type
            <span class="text-danger error-text">
              * <span v-if="v$.usertypeuid.$error"> select an option </span>
            </span></label
          >
          <select
            v-model="formFields.usertypeuid"
            class="custom-field"
            :class="{ errorCustomField: v$.usertypeuid.$error }"
          >
            <option
              v-for="(item, index) in userType"
              :key="index"
              v-bind:value="item.type_uid"
            >
              {{ item.type_user }}
            </option>
          </select>
        </div>
        <div class="inputCol">
          <label
            >Username
            <span class="text-danger error-text">
              *
              <span v-if="v$.user_username.$error"> username is required </span>
            </span></label
          >
          <input
            v-model="formFields.user_username"
            type="text"
            class="custom-field"
            :class="{ errorCustomField: v$.user_username.$error }"
          />
        </div>
        <div class="inputCol">
          <label
            >Password
            <span class="text-danger error-text">
              *
              <span v-if="v$.user_password.$error"> password is required </span>
            </span></label
          >
          <input
            v-model="formFields.user_password"
            type="password"
            class="custom-field"
            :class="{ errorCustomField: v$.user_password.$error }"
          />
        </div>
        <!-- <div class="inputCol text-end">
          <a href="#">Forgot Your Password</a>
        </div> -->
        <div class="button-outer inputCol">
          <button class="custom-button">Login</button>
        </div>
        <!-- <div class="inputCol text-center">
          <a class="custom-link" @click="authFB">
                    Login with Facebook
                </a>
         </div> -->
      </form>
    </div>
  </div>
</template>
<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import callService from "@/services/Service";
const { getData, getByID, postData } = callService();
import axios from "axios";

export default{
  name: "Login",
  data() {
    return {
      userGroup: [],
      userType: [],
      errorMessage: null,
      successMessage: null,
    };
  },
  setup() {
    const formFields = reactive({
      usergroupuid: "",
      usertypeuid: "",
      user_username: "",
      user_password: "",
    });

    const rules = {
      usergroupuid: {
        required,
      },
      usertypeuid: {
        required,
      },
      user_username: {
        required,
      },
      user_password: {
        required,
      },
    };
    const v$ = useVuelidate(rules, formFields);
    return {
      formFields,
      v$,
    };
  },

  async created() {
    
    try {
      const resData = await getData("login/getUserGroup");
      if (resData.status === 200 && resData.data.statuscode === 1) {
        this.userGroup = resData.data.result;
      }
    } catch (e) {
      this.error = e;
    }

    /*
    try {
      const response = await axios.get(
        "https://inspiredhealthhub.com:8044/login/getUserGroup"
      );
      this.userGroup = response.data.result;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
      */

    /*
    if(this.$cookies.get("token")) {
      const token = this.$cookies.get("token");
      localStorage.setItem("LogIn", JSON.stringify(token));
      console.log("pwlphub", token);
      const extensionresponse = JSON.parse(localStorage.getItem("LogIn"));
        console.log("yyyyyyyyyyyyyyyyyyyyy", extensionresponse.extension);
        if (extensionresponse.extension !== "") {
          localStorage.setItem("ServerPath", "/ws");
          localStorage.setItem("EchoCancellation", "1");
          localStorage.setItem("VoiceMailSubscribe", "1");
          localStorage.setItem("SipDomain", "dailer.caretalkhosting.com");
          localStorage.setItem("profileName", extensionresponse.extension);
          localStorage.setItem("SipUsername", extensionresponse.extension);
          localStorage.setItem("AspectRatio", "1.33");
          localStorage.setItem("VideoSrcId", "default");
          localStorage.setItem("SipPassword", "SIP123ext");
          localStorage.setItem("loglevel:webpack-dev-server", "WARN");
          localStorage.setItem("AutoGainControl", "1");
          localStorage.setItem("wssServer", "dailer.caretalkhosting.com");
          localStorage.setItem(
            "RingOutputId",
            "724c6dad96e08e09da3ed3fb21ed79a3966a0e916eb4a267d108a02ae19ea4ee"
          );
          localStorage.setItem("WebSocketPort", "8089");
          localStorage.setItem("NoiseSuppression", "1");
          localStorage.setItem("language", "en");
          localStorage.setItem("WelcomeScreenAccept", "yes");
          localStorage.setItem("InstanceId", String(Date.now()));
          localStorage.setItem(
            "16750799442502185-Buddies",
            '{"TotalRows":0,"DataCollection":[]}'
          );
          localStorage.setItem("profileUserID", "167508055739511B1");
          localStorage.setItem("ChatEngine", "SIMPLE");
          localStorage.setItem("AudioOutputId", "default");
          localStorage.setItem("AudioSrcId", "default");
          localStorage.setItem("VideoOrientation", "rotateY(180deg)");
          localStorage.setItem(
            "profileVcard",
            '{"TitleDesc":"","Mobile":"","Email":"","Number1":"","Number2":""}'
          );
          localStorage.setItem("Notifications", "0");
          localStorage.setItem(
            "167508055739511B1-Buddies",
            '{"TotalRows":0,"DataCollection":[]}'
          );
        } else {
          setTimeout(function () {
            ShowContacts();
          }, 1000);
        }
    }
        */
    if(localStorage.getItem("LogIn")){
      const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
      const redirction =
        type_uid === "ipg4bi4vg5"
          ? "SuperUserDashboard"
          : type_uid === "ipg4bi4vg6"
          ? "EnrollmentAdminProfile"
          : type_uid === "ipg4bi4vg7"
          ? "ClinicalAdminProfile"
          : type_uid === "ipg4bi4vg8"
          ? "GeneralAdminProfile"
          : type_uid === "ipg4bi4v10"
          ? "agentprofile"
          : type_uid === "ipg4bi4vg9"
          ? "EnrollmentManagerProfile"
          : type_uid === "ipg4bi4v11"
          ? "PhysicianAdminProfile"
          : type_uid === "ipg4bi4v12"
          ? "PhysicianProfile"
          : type_uid === "Enrollment"
          ? "managerProfile"
          : "/login";
      this.$router.push({ name: redirction });
    }
  },
  methods: {
   
    async selectGroup(uid) {
      try {
        const resData = await getByID("login/getUserById/",uid);
        if (resData.status === 200 && resData.data.statuscode === 1) {
          this.userType = resData.data.result;
        }
      } catch (e) {
        this.error = e;
      }
    },

    /*
    async selectGroup(uid) {
      console.log("uid", uid);
      try {
        const response = await axios.get(
          "https://inspiredhealthhub.com:8044/login/getUserById/" + uid
        );
        this.userType = response.data.result;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    */

    hideError: function () {
      this.errorMessage = null;
    },
    authFB() {
      this.$router.push({ name: "Enrollment" });
    },
    async submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          var resData = null;
          var bs = null;
          if (
            window.location.hostname == "localhost" 
            //||window.location.hostname.indexOf("192.168.0") > -1
          ) {
            bs = "https://localhost:8080/";
            const resData = await postData("login/userLogin", this.formFields);
          if(resData.status === 200 && resData.data.statuscode === 1) {
            if (resData.data.result.extension !== "") {
             localStorage.setItem("LogIn",JSON.stringify(resData.data.result));
              localStorage.setItem("ServerPath", "/ws");
              localStorage.setItem("EchoCancellation", "1");
              localStorage.setItem("VoiceMailSubscribe", "1");
              localStorage.setItem("SipDomain", "dailer.caretalkhosting.com");
              localStorage.setItem(
                "profileName",
                resData.data.result.extension
              );
              localStorage.setItem(
                "SipUsername",
                resData.data.result.extension
              );
              localStorage.setItem("AspectRatio", "1.33");
              localStorage.setItem("VideoSrcId", "default");
              localStorage.setItem("SipPassword", "SIP123ext");
              localStorage.setItem("loglevel:webpack-dev-server", "WARN");
              localStorage.setItem("AutoGainControl", "1");
              localStorage.setItem("wssServer", "dailer.caretalkhosting.com");
              localStorage.setItem(
                "RingOutputId",
                "724c6dad96e08e09da3ed3fb21ed79a3966a0e916eb4a267d108a02ae19ea4ee"
              );
              localStorage.setItem("WebSocketPort", "8089");
              localStorage.setItem("NoiseSuppression", "1");
              localStorage.setItem("language", "en");
              localStorage.setItem("WelcomeScreenAccept", "yes");
              localStorage.setItem("InstanceId", String(Date.now()));
              localStorage.setItem(
                "16750799442502185-Buddies",
                '{"TotalRows":0,"DataCollection":[]}'
              );
              localStorage.setItem("profileUserID", "167508055739511B1");
              localStorage.setItem("ChatEngine", "SIMPLE");
              localStorage.setItem("AudioOutputId", "default");
              localStorage.setItem("AudioSrcId", "default");
              localStorage.setItem("VideoOrientation", "rotateY(180deg)");
              localStorage.setItem(
                "profileVcard",
                '{"TitleDesc":"","Mobile":"","Email":"","Number1":"","Number2":""}'
              );
              localStorage.setItem("Notifications", "0");
              localStorage.setItem(
                "167508055739511B1-Buddies",
                '{"TotalRows":0,"DataCollection":[]}'
              );
            } else {
              setTimeout(function () {
                ShowContacts();
              }, 3000);
            }
            // SuperUser
            if (resData.data.result.type_uid === "ipg4bi4vg5") {
              localStorage.setItem("LogIn",JSON.stringify(resData.data.result));
              this.$router.push({name: "SuperUserDashboard",});
            }
            // Enrollment Admin
            if (resData.data.result.type_uid === "ipg4bi4vg6") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({
                name: "EnrollmentAdminDashboard",
              });
            }
            // Clinical Admin
            if (resData.data.result.type_uid === "ipg4bi4vg7") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({
                name: "ClinicalAdminDashboard",
              });
            }
            //General Admin
            if (resData.data.result.type_uid === "ipg4bi4vg8") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({
                name: "GeneralAdminDashboard",
              });
            }
            //Enrollment Manager
            if (resData.data.result.type_uid === "ipg4bi4vg9") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({ name: "EnrollmentManagerDashboard" });
            }
            //Enrollment Agent
            if (resData.data.result.type_uid === "ipg4bi4v10") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({ name: "enrollmentform" });
            }
            //Physician Admin
            if (resData.data.result.type_uid === "ipg4bi4v11") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({
                name: "PhysicianAdminDashboard",
              });
            }
            //Physician
            if (resData.data.result.type_uid === "ipg4bi4v12") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              // this.updateCallStatus(resData.data.result.user_uid);
              this.$router.push({ name: "WaitingRoom" });
            }
          } else {
            this.$router.push("/");
            this.errorMessage = resData.data.message;
            setTimeout(
              function () {
                this.hideError();
              }.bind(this),
              4000
            );
          }
          }else{
            const responseOfinspiredhealthhub = await axios.post(
              "https://inspiredhealthhub.com:8044/login/userLogin/",
              this.formFields
            );
            const responseOfpwlphub = await axios.post(
              "https://pwlphub.com:8066/login/userLogin/",
              this.formFields
            );
            const responseOfawrxhub = await axios.post(
              "https://awrxhub.com:8077/login/userLogin/",
              this.formFields
            );
            const responseOffitformhub = await axios.post(
              "https://fitformhub.com:8033/login/userLogin/",
              this.formFields
            );
            
            if (
              responseOfinspiredhealthhub.status === 200 &&
              responseOfinspiredhealthhub.data.statuscode === 1
            ) {
              resData = responseOfinspiredhealthhub;
              window.location.href = "https://inspiredhealthhub.com/login";
              localStorage.setItem("LogIn",JSON.stringify(resData.data.result));
            }
             else if (
              responseOfpwlphub.status === 200 &&
              responseOfpwlphub.data.statuscode === 1
            ) {
              const generatedToken = Math.random().toString(36).slice(2);
              const res = await axios.post("https://pwlphub.com:8066/api/createUserLoginToken",{ user_uid: responseOfpwlphub.data.result.user_uid,
                        login_token: generatedToken  });
              if(res.status==200 && res.data.statuscode==1){
              window.location.href ="https://pwlphub.com/switchuser/"+generatedToken;
              }
       
            }else if (
              responseOfawrxhub.status === 200 &&
              responseOfawrxhub.data.statuscode === 1
            ) {
              const generatedToken = Math.random().toString(36).slice(2);
              const res = await axios.post("https://awrxhub.com:8077/api/createUserLoginToken",{ user_uid: responseOfawrxhub.data.result.user_uid,
                        login_token: generatedToken  });
              if(res.status==200 && res.data.statuscode==1){
                 window.location.href ="https://awrxhub.com/switchuser/"+generatedToken;
              }
            }else if (
              responseOffitformhub.status === 200 &&
              responseOffitformhub.data.statuscode === 1
            ) {
              const generatedToken = Math.random().toString(36).slice(2);
              const res = await axios.post("https://fitformhub.com:8033/api/createUserLoginToken",{ user_uid: responseOffitformhub.data.result.user_uid,
                        login_token: generatedToken  });
              if(res.status==200 && res.data.statuscode==1){
                 window.location.href ="https://fitformhub.com/switchuser/"+generatedToken;
              }  
       
            } else {
              this.$router.push("/");
              this.errorMessage = "Invalid Username or Password.";
              setTimeout(
                function () {
                  this.hideError();
                }.bind(this),
                4000
              );
            }
          
          }

        } catch (e) {
          this.error = e;
        }
      }
    },
  },
};
</script>
<style scoped>
.hClass {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #999393;
  line-height: 0.1em;
  margin: 18px 0 22px;
}

.hClass span {
  background: #fff;
  padding: 0 10px;
}
</style>